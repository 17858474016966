import * as React from "react";
import "./TimeIsMoney.css";
import { inputCategories } from "../../utils/enums";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

export const TimeIsMoney = () => {
  const [ratePerHour, setRatePerHour] = React.useState<string>();
  const [moneySpent, setMoneySpent] = React.useState<string>();
  const [timeSpent, setTimeSpent] = React.useState<string>();
  const [isTimeSpentActive, setIsTimeSpentActive] = React.useState(false);

  React.useEffect(() => {
    const rph = localStorage.getItem("rph");
    if (rph) {
      !isNaN(parseInt(rph)) && setRatePerHour(rph);
    }
  }, []);

  const toHoursAndMinutes = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  };

  const createTimeSpentMessage = (totalMinutes: number) => {
    const timeSpentObject = toHoursAndMinutes(totalMinutes);

    let message = "";
    if (timeSpentObject.hours > 0) message += `${timeSpentObject.hours} Hours `;
    if (timeSpentObject.minutes > 0)
      message += `${timeSpentObject.minutes} Minutes`;

    return message;
  };

  const handleChangeRatePerHour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value, 10);
    if (isNaN(val)) {
      setRatePerHour("");
      localStorage.setItem("rph", "");
    } else {
      setRatePerHour((val >= 0 ? val : 0).toString());
      localStorage.setItem("rph", (val >= 0 ? val : 0).toString());
    }
  };

  const handleChangeMoneySpent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value, 10);
    if (isNaN(val)) {
      setMoneySpent("");
      setTimeSpent("");
    } else if (val >= 0) {
      const rph = ratePerHour ? parseFloat(ratePerHour) : -1;
      if (rph > 0) {
        const minutes = (val / rph) * 60;
        setTimeSpent(createTimeSpentMessage(minutes));
      }
      setMoneySpent((val >= 0 ? val : 0).toString());
    } else {
      setMoneySpent("0");
    }
  };

  const handleChangTimeSpent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value, 10);
    if (isNaN(val)) {
      setMoneySpent("");
      setTimeSpent("");
    } else if (val >= 0) {
      const rph = ratePerHour ? parseFloat(ratePerHour) : -1;
      if (rph > 0) {
        setMoneySpent(
          ((val / 60) * rph)
            .toFixed(2)
            .replace(/[.,]00$/, "")
            .toString()
        );
      }
      setTimeSpent((val >= 0 ? val : 0).toString());
    } else {
      setTimeSpent("0");
    }
  };

  const handleChangeInputType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTimeSpentActive(e.target.checked);
  };

  return (
    <div>
      <div className="information-wrapper">
        <Box
          className="information-section"
          sx={{ width: { xs: 300, md: 700 } }}
        >
          <Typography
            variant="h3"
            className="information-title"
            sx={{
              marginBottom: { xs: "20px", md: "40px" },
              marginTop: { xs: "20px", md: "120px" },
              textAlign: "center",
            }}
          >
            Time Is Money!
          </Typography>
          <Typography variant="subtitle1" className="information-subtitle">
            <b>Welcome to Time Is Money!</b> 🚀 Discover a new money perspective
            with ease.
            <br />
            <b>1. Unveil Your Worth!</b> Enter your Rate Per Hour at the top.
            <br />
            <b>2. Choose Wisely!</b> Toggle to enter time or money spent.
            <br />
            <b>3. Witness the Magic!</b> See the eye-opening comparison.
            <br />
            <b>🌟 Our Mission:</b> Empower financial stability and value time.
            <br />
            <b>🎉 Join us now!</b> Embrace enlightenment today.
            <br />
            <b>🚀 Time Is Money - Your Path to Prosperity! 🚀</b>
          </Typography>
        </Box>
      </div>
      <div className="rate-per-hour-section">
        <TextField
          label="Rate Per Hour"
          value={ratePerHour}
          onChange={handleChangeRatePerHour}
          name={inputCategories.ratePerHour}
          id="formatted-numberformat-input"
          InputLabelProps={{ shrink: ratePerHour ? true : false }}
          InputProps={{
            inputComponent: NumericFormatCustom as any,
            endAdornment: (
              <InputAdornment position="end">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          required={true}
        />
      </div>
      <Box
        className="inputs-section"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { md: "center" },
          alignItems: { xs: "center" },
        }}
      >
        <TextField
          label="Money Spent"
          value={moneySpent}
          onChange={handleChangeMoneySpent}
          name={inputCategories.moneySpent}
          id="formatted-numberformat-input"
          InputLabelProps={{ shrink: moneySpent ? true : false }}
          InputProps={{
            inputComponent: NumericFormatCustom as any,
            endAdornment: (
              <InputAdornment position="end">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          disabled={isTimeSpentActive}
        />
        <div className="categories-switch-wrapper">
          <AttachMoneyIcon
            style={{ color: isTimeSpentActive ? "gray" : "green" }}
          />
          <Switch
            checked={isTimeSpentActive}
            onChange={handleChangeInputType}
          />
          <AccessTimeIcon
            style={{ color: isTimeSpentActive ? "rgb(0, 132, 255)" : "gray" }}
          />
        </div>
        <TextField
          label="Time Spent (In Minutes)"
          value={timeSpent}
          onChange={handleChangTimeSpent}
          name={inputCategories.timeSpent}
          id="formatted-numberformat-input"
          InputLabelProps={{ shrink: timeSpent ? true : false }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccessTimeIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          disabled={!isTimeSpentActive}
        />
      </Box>
      <Divider
        sx={{
          marginTop: { xs: "20px", md: "40px" },
          marginBottom: { xs: "20px", md: "40px" },
        }}
      ></Divider>
    </div>
  );
};
