import "./Home.css";
import { TimeIsMoney } from "../../components/TimeIsMoney/TimeIsMoney";
import Box from "@mui/material/Box";

export const Home = () => {
  return (
    <Box className="home-main" sx={{ top: { xs: "60px", md: "60px" } }}>
      <TimeIsMoney />
    </Box>
  );
};
