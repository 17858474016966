import * as React from "react";
import "./Navbar.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { Icon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import avatarPicture from "../../images/profile-image.jpg";

const menuItems = [
  {
    name: "instagram",
    href: "https://www.instagram.com/idansvirsky/",
    icon: <InstagramIcon />,
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/in/idansvirsky",
    icon: <LinkedInIcon />,
  },
  {
    name: "github",
    href: "https://github.com/Svirsky25",
    icon: <GitHubIcon />,
  },
];

const NAVBAR_TITLE = "Idan Svirsky";

export const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ maxHeight: { xs: "60px", md: "60px" } }}
      color="default"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "oswaldRegular",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              "&:focus": {
                outline: "none",
              },
            }}
          >
            {NAVBAR_TITLE}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {menuItems.map((menuItem) => (
                <MenuItem key={menuItem.name} onClick={handleCloseNavMenu}>
                  <a
                    href={menuItem.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-menu-anchor"
                  >
                    <Icon className="nav-menu-anchor-icon">
                      {menuItem.icon}
                    </Icon>
                  </a>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "oswaldRegular",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {NAVBAR_TITLE}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menuItems.map((menuItem) => (
              <a
                key={menuItem.name}
                href={menuItem.href}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-menu-anchor"
              >
                <Icon
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 1,
                    marginX: 1,
                  }}
                >
                  {menuItem.icon}
                </Icon>
              </a>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Avatar alt="Profile" src={avatarPicture} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
